<template>
  <work-schedules />
</template>

<script>
import WorkSchedules from '@/views/components/calendar/WorkSchedules.vue'

export default {
  components: {
    WorkSchedules,
  },
}
</script>
